import WebHttpConnection from './http-dispatcher';
import {urlConfigs} from "../configs/config";

export const sendLoginRequest = (headers, successFn, errorFn) => {
    WebHttpConnection.sendAjaxRequest({
        url: urlConfigs.BASE_URL + '/rest',
        type: 'POST',
        headers: headers,

        onSuccess: (data, textStatus) => {
            if (textStatus === 'success') {
                successFn(data);
            } else {
                errorFn(data);
            }
        },

        onError: error => errorFn(error)
    });
};

export const getInfo = (url, successFn, errorFn) => {
    WebHttpConnection.sendAjaxRequest({
        url: url,
        type: 'GET',

        onSuccess: (data, textStatus) => {
            if (textStatus === 'success') {
                successFn(data);
            } else {
                errorFn(data);
            }
        },

        onError: error => errorFn(error)
    });
};

/**
 * Method to fetch API data. Normally request type is POST
 * @param url
 * @param token
 * @param successFn
 * @param errorFn
 */
export const getMetaInfo = (url, token, successFn, errorFn) => {
    WebHttpConnection.sendAjaxRequest({
        url: url,
        type: 'POST',
        headers: token ? {"Authorization": 'Bearer ' + token} : '',

        onSuccess: (data, textStatus) => {
            if (textStatus === 'success') {
                successFn(data);
            } else {
                errorFn(data);
            }
        },

        onError: error => errorFn(error)
    });
};
