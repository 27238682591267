import React, {lazy, useEffect,  useState } from 'react';
import $ from "jquery";
import {getInfo} from 'service/request-handler';

const MetaTable = ({url , panelHeading, panelButtons, showDefault, alignClass = 'text-right', collapseButtonClass = ''}) => {
    const [htmlContent, setHtmlContent] = useState('');

    const populateMetaTable = () => {
        getInfo(url, function (data) {
            let html = "";
            for (var key in data) {
                html += '<tr>' +
                    '<td class="text-bold">' + key + '</td>' +
                    '<td class="' + alignClass + '">' + data[key] + '</td>' +
                    '</tr>';
            }

            html = '<table class="table table-lg meta-table">' + html + '</table>';
            setHtmlContent(html);
            return false;
        }, () => {});
    };

    useEffect(() => {
       populateMetaTable();
       $(".meta-table").on('populateMetaTable',function(){
           populateMetaTable();
       });
    }, []);

    if (!panelHeading)  panelHeading = 'Additional';

    let panelButtonHTML = '';
    let collapsedClass = 'panel panel-flat panel-collapsed';
    let rotatedClass = 'rotate-180';
    if (panelButtons){
        for(let key in panelButtons){
            panelButtonHTML += '<button type="button" class="btn btn-primary heading-btn pull-right ' + panelButtons[key].class + '">' + panelButtons[key].caption +  '</button>';
        }
        collapsedClass = 'panel panel-flat';
        rotatedClass = '';
    }

    if (showDefault){
        collapsedClass = 'panel panel-flat';
        rotatedClass = '';
    }

    return (

        <div class={collapsedClass}>
            <div class="panel-heading">
                <h5 class="panel-title text-semibold">{panelHeading}</h5>
                <div class="heading-elements">
                    <span dangerouslySetInnerHTML={{ __html: panelButtonHTML }}></span>
                    <ul class="icons-list">
                        <li><a data-action="collapse" class={rotatedClass + " " + collapseButtonClass}></a></li>
                    </ul>
                </div>
            </div>
            <div class="table-responsive horizontal-inline-form">
                <table class="table table-lg meta-table" dangerouslySetInnerHTML={{ __html: htmlContent }}>
                </table>
            </div>
        </div>

    )
};

export default MetaTable;
