import React, {useEffect} from 'react';
import $ from 'jquery';
import './imports/formImports';

const InputTextArea = ({id, name, label, options, required, xhtml, maxlength, staticContent}) => {
    const editor = options['editor'];

    useEffect(() => {
        if (editor) {
            $("#" + id).summernote({
                    toolbar: [
                        // [groupName, [list of button]]
                        ['style', ['style' , 'bold', 'italic', 'underline', 'clear']],
                        ['undo_redo', ['undo' , 'redo']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['super_sub', ['superscript', 'subscript']],
                        ['fontsize', ['fontsize']],


                        ['link', ['link','table','hr']],

                        ['misc', ['print','specialchars','codeview']],

                        ['color', ['color']],

                    ],
                    height: 400,

                    callbacks: {
                        onChange: function(contents, $editable) {
                            $(this).val(contents);
                        },

                        onInit: function() {
                            $(this).summernote('code' , staticContent);
                        },
                    },
                });

        } else if (maxlength > 0) {
            const maxlength_rand = (element) => {
                if (element) {
                    if (!id.includes('maxlength')) { //if (!('maxlength' in element)) {        //  TODO: verify the logic
                        const max = element.attributes?.maxlength?.value;
                        element.onkeyup = function () {
                            $('#' + id + '_count').val(maxlength - this.value?.length);
                            if (this.value?.length >= max) return false;
                        };
                    }
                }
            };

            maxlength_rand(document.getElementById(id));
            $('#' + id).trigger('keyup');
            $('#' + id).css('resize','vertical');
        }
    }, []);

    const getEditorXhtml = () => {
        if (!editor) {
            return (
                <>
                    {maxlength > 0 &&
                    <>
                        <br/>
                        <p><input type="text" className="form-control"
                                  style={{width:'50px', display:'inline-block', marginRight: '10px'}} readOnly="readonly"
                                  id={id + '_count'} value={maxlength}/> Characters Left</p>
                        <br/>
                    </>}
                </>
            );
        }
    };

    return (
        <>
            <label for={id}>
                {label}
                {options.help && <span className="help-block">{options.help}</span>}
                {required && <span className="text-danger">&nbsp;*</span>}
            </label>

            <textarea name={name} id={id} rows="5" customLabel={label} maxlength={maxlength} cols="80" className="form-control"/>
            {getEditorXhtml()}
        </>
    );
};

export default InputTextArea;
