import  {
    React, CompanyRoutes, useEffect
}  from 'library/base/baseContainerImports';
import $ from 'jquery';
import {useHistory} from "react-router-dom";
import {hideMenuMobile} from "../topBar/TopBar";
import {useSelector} from "react-redux";

/**
 * This side bar lists the menu with paths only, no attachment of corresponding components here
 * In Content page, the list of cases in a switch-case determines which component should be rendered
 *
 * @returns {*}
 * @constructor
 */
const SideBar = () => {
    const sideConfig = useSelector(state => state.userMeta.side);
    const username = useSelector(state => state.userMeta.name);
    const companyLogo = useSelector(state => state.userMeta.meta.logo);
    const myAccountUrl = CompanyRoutes.ADMIN.MY_ACCOUNT.path;
    const logoClass = (companyLogo == '' ? ' hide' : '');

    return (
        <div className="sidebar sidebar-main">
            <div className="sidebar-content">

                <div className="sidebar-user">
                    <div className="category-content">
                        <div className="company-dashboard-logo-wrapper media">
                            <a href={myAccountUrl}>
                                <div className={' media-left ' + logoClass} style={{backgroundImage: `url(${companyLogo})` }} >&nbsp;</div>
                                <div className="media-body">
                                    <span className="media-heading text-semibold">{username}</span>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>


                <div className="sidebar-category sidebar-category-visible">
                    <div className="category-content no-padding">
                        <ul className="navigation navigation-main navigation-accordion sidebar-navi">
                            {displayRouteMenu(sideConfig)}
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SideBar;

/**
 * Render a nested hierarchy of route configs with unknown depth/breadth
 */
function displayRouteMenu(routes) {
    /**
     * Render a single route as a list item link to the config's pathname
     */

    // loop through the array of routes and generate an unordered list
    return (
        <>
            {routes.map(route => {
                // if this route has sub-routes, then show the ROOT as a list item and recursively render a nested list of route links
                if (route.routes) {
                    return (
                        <React.Fragment key={route.key}>
                            <SingleRoute route={route}/>
                            {displayRouteMenu(route.routes)}
                        </React.Fragment>
                    );
                }

                // no nested routes, so just render a single route
                return <SingleRoute route={route}/>;
            })}
        </>
    );
}

export const SingleRoute = ({route}) => {
    const history = useHistory();

    useEffect(() => {
        $("ul.sidebar-navi").on("click", "li", function () {
            $("ul.sidebar-navi li").removeClass("active");
            $(this).addClass("active");
        });

        activateCurrentMenu();
    }, []);

    const activateCurrentMenu = () => {
        const current = window.location.pathname;

        $('ul.sidebar-navi li a').each(function(){
            const $this = $(this);
            if($this.attr('href') === current){
                $this.parent('li').addClass('active');
            }
        })
    };

    const navigateToPath = (e, path) => {
        e.preventDefault();

        // Below method only executed in Mobile
        hideMenuMobile();

        if (path) {
            history.push(path);
        }
    };

    if (route.href || route.path) {
        return (
            <li key={route.key} className={route.class}>
                <a href={route.href || route.path} onClick={ e => navigateToPath(e, route.href || route.path) }>
                    <i className={route.iconClass || 'icon-file-text2'}> </i>
                    <span>{route.title || route.key}</span>
                </a>
            </li>
        );
    } else {
        return (
            <li className={route.class}><span>{route.title}</span>
                <i className={route.iconClass} title="" data-original-title={route.title}/></li>
        );
    }
};
