import {
    React,
    PublicTemplate,
    useActionUrl,
    $,
    useEffect,
    useLocation,
    queryString,
    useState
} from 'library/base/baseContainerImports';
import MainForm from "configs/Form/Admin/User/ResetPassword";
import {getInfo} from "../../../../service/request-handler";

const Container = () => {

    const location = useLocation();
    const {hashcode} = queryString.parse(location.search);
    const [hashResult, setHashResult] = useState(null);

    const {getFormActions} = useActionUrl();
    const {formAction, formDefinitionAction} = getFormActions(null, {hashcode});

    useEffect( () => {

        getInfo(formAction + '&check=result', checkFormResult, () => console.error('Something went wrong'));

    }, []);


    const checkFormResult = (data) => {
        if (data === 0){
            setHashResult('The password reset link you have clicked is invalid. Please contact Team Works.');
        }else if(data === 2){
            setHashResult('Your password reset link has timed out. Please <a href="/admin/forgotten-password">click here to receive a new password reset link.</a>');
        }else{
            setHashResult(1);
            $("#resetpass").click(function(){
                $(".form_data").processForm({mode : "return"});
                return false;
            });
        }
    };

    return (
        <div>
            {hashResult !== 1 &&
                <div className="page-container login-container">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="content">
                                <div className="panel panel-body login-form">
                                    <div className="text-center">
                                        <div className="activate_message">
                                            <div className="nNote alert alert-danger alert-styled-left alert-bordered no-margin-bottom" >
                                                <p><strong dangerouslySetInnerHTML={{ __html: hashResult }}></strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {hashResult === 1 &&
                <PublicTemplate
                    formAction={formAction}
                    formDefinitionAction={formDefinitionAction}
                    mainForm={MainForm}
                    title={'Reset Your Password'}
                    subTitle={'Enter your new password below.'}
                    isRequestFailed={false}
                    errorMessage={'We could not send reset password request. Please try again.'}
                />
            }
        </div>
    );
};

export default Container;
