import React, {Suspense} from "react";
import {RenderRoutes, useAppRoutes} from "../../routes/routes";
import ErrorBoundary from "../../app/ErrorBoundry";

const DualSideBarContent = () => {
    const {getRoutes} = useAppRoutes();
    const ROUTES = getRoutes();
    return (
        <>
            <ErrorBoundary>
                <Suspense fallback={<div>  </div>}>
                    <RenderRoutes routes={ROUTES}/>
                </Suspense>
            </ErrorBoundary>
        </>
    );
};

export default DualSideBarContent;
