import React from "react";
import {getRouteForModule, getRouteObject} from './adminRouteUtils';

/**
 * Admin route configuration
 *
 * Use getRouteForModule to create routes for all operations including main container
 * Use getRouteObject to get a single route object
 *
 * Check above method signatures in `adminRouteUtils` for more information
 */
export const AdminRoutes = {
    "ADMIN": {
        ...getRouteObject(),
        "MY_ACCOUNT": getRouteForModule('my-account', 'User/MyAccount', 'My Account'),
        "CUSTOMERS": {
            ...getRouteForModule('customers', 'Customer', 'Customers', 'Customer', 'icon-users4'),
            "SYNC": getRouteForModule('customers/sync'),
            "CREDIT_CARDS": getRouteForModule('customers/credit-cards', 'Customer', 'Credit Cards', 'Credit Card'),
            "USERS": getRouteForModule('customers/users', 'Customer/User', 'Users', 'User', 'icon-users4'),
        },
        "PLANS": getRouteForModule('plans', 'Plan', 'Plans', 'Plan','icon-file-text2'),
        "SUBSCRIPTIONS": {
            ...getRouteForModule('subscriptions', 'Subscription', 'Subscriptions', 'Subscription','icon-stack'),
            "ACTIVATE": getRouteForModule('subscriptions/activate'),
        },
        "PAYMENTS": {
            ...getRouteForModule('payments', 'Payment', 'Payments', 'Payment','icon-coin-dollar'),
            "REFUND": getRouteForModule('refunds', 'Refund', 'Refunds', 'Refund','icon-coin-dollar')
        },
        "USERS": getRouteForModule('users', 'User', 'Users', 'User','icon-users4'),
        "MODULES": getRouteForModule('modules', 'Module', 'Modules', 'Module','icon-grid6'),
        "EMAILS": getRouteForModule('settings/emails', 'Emails', 'Emails', 'Emails','icon-envelope'),
        "ACCOUNTS": getRouteForModule('settings/accounts', 'Accounting', 'Accounting', 'Accounting','icon-cash'),
        "CRM": getRouteForModule('settings/crm', 'Crm', 'CRM', 'CRM','icon-users2'),
        "PROMO": getRouteForModule('promotional-codes', 'PromoCode', 'Promotional Codes', 'Promotional Code','icon-piggy-bank'),
        "SETTINGS": {
           "XERO": getRouteForModule('settings/xero', 'Settings/Xero', 'Xero Connect', 'Xero Connect','icon-popular-app icon-xero'),
           "MAILCHIMP": getRouteForModule('settings/mailchimp', 'Settings/MailChimp', 'MailChimp Connect', 'MailChiimp Connect','icon-popular-app icon-mailchimp')
        }
    }
};