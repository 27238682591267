import React from 'react';
import TopBar from "./layoutComponents/topBar/TopBar";
import Content from "./layoutComponents/DualSideBarContent";
import SideBar from "./layoutComponents/sidebar/SideBar";

const DualSideBarLayout = () => {

    return (
        <div class="sidebar-opposite-visible">
            <TopBar/>
            <div className="page-container" style={{minHeight: '95vh'}}>
                <div className="page-content">
                    <SideBar/>
                    <Content/>
                </div>
            </div>
        </div>
    );
};

export default DualSideBarLayout;
