import React from 'react';
import Content from "./layoutComponents/Content";
import StudentTopBar from './layoutComponents/topBar/StudentTopBar'
import StudentSideBar from './layoutComponents/sidebar/StudentSideBar'

const StudentSideBarLayout = () => {

    return (
        <>
            <StudentTopBar/>
            <div className="page-container" style={{minHeight: '95vh'}}>
                <div className="page-content">
                    <StudentSideBar/>
                    <Content/>
                </div>
            </div>
        </>
    );
};

export default StudentSideBarLayout;
