import {
    React, useActionUrl, Form, PublicBlankTemplate,
    PageHeader, AdminRoutes, useFormInit, useEffect, useState, getGetParam, showUserMessage, showUserMessageHTML, $
} from 'library/base/baseContainerImports';

import LoginForm from 'modules/Admin/User/Login/login';

const Container = () => {

    const {getFormActions} = useActionUrl();
    const {formAction, formDefinitionAction} = getFormActions(null, {'type':'signup'});
    const {loginFormAction, loginFormDefinitionAction} = getFormActions(null, {'type':'login'});

   // const [htmlContent, setHtmlContent] = useState('');
    const {fetchFormData} = useFormInit({formAction});
    const {loginFetchFormData} = useFormInit({loginFormAction});

    const onRender = () => {
        fetchFormData();
        initForm();
    };

    const initForm = () => {

        $(".terms-wrapper label").html($(".terms-wrapper label").html().replace(/Accepting Our Terms and Conditions/g,"By signing up for an account, you accept our <a href='/terms' target='_blank'>Terms and Conditions</a>"));

        $("#signup").click(function(){
            $(".form_data").processForm({
                mode : "callback",
                callback: function(valid,response){
                    if (valid){
                        showUserMessageHTML (response.info.title,response.info.message, 1,function(){
                            window.location = response.redirect;
                            return false;
                        });
                        return false;
                    }else{
                        return false;
                    }
                }
            });
            return false;
        });

    };


    useEffect( () => {
        //setHtmlContent(innerContent);
    }, []);

    return (
        <div>
            <div class="row">
                <div class="col-md-4 col-md-offset-4 text-center pb-20 col-xs-6 col-xs-offset-3 pt-20">
                    <img title="Team Works" class="signup-logo" src="/logo.png"/>
                </div>
            </div>
            <div class="row desktop-flex">
                <div class="col-md-8">
                    <div class="panel registration-form" >
                        <div class="panel-body">
                            <div class="text-center">
                                <h5 class="content-group-lg pb-15">Signup for an account</h5>
                            </div>

                            <Form
                                id="form-data"
                                action={formAction}
                                formClass='form_data generic_form'
                                encType='application/x-www-form-urlencoded'
                                jsonLoadAction={formDefinitionAction}
                                jsonLoadActionHandler={onRender}
                                />

                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="panel panel-body login-form1">
                        <div class="text-center">
                            <h5 class="content-group-lg pb-15">Log in to your account</h5>
                        </div>
                        <LoginForm
                            ContainerClass="signup_login_combined"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

const signupContainer = () => {
  return (
      <PublicBlankTemplate
          content={<Container/>}
      />
  );
};

export default signupContainer;
