import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {AdminRoutes} from './adminRoutes';
import {CompanyRoutes} from './companyRoutes';
import each from 'foreach-object';
import {useSelector} from "react-redux";
import {queryString, useLocation} from "../library/base/baseContainerImports";

/**
 * IMPORTANT: Do not edit below methods unless a change in routing mechanism is required
 *
 */

export const useAppRoutes = () => {
    const location = useLocation();
    const {authhash} = queryString.parse(location.search);
    const isCompany = useSelector(state => state.userMeta?.meta?.is_company) || authhash;
    const routes = isCompany ? {...CompanyRoutes} : {...AdminRoutes};
    const getRoutes = (routeMapping) => {
        let routeArray = [];
        let currentRouteMapping = routeMapping ? routeMapping : routes;

        each(currentRouteMapping, (value, key) => {
            if (value && value.path && value.container) {
                routeArray[routeArray.length] = {
                    path: value.path,
                    key: key,
                    exact: true,
                    title: value.title,
                    pageHeaderLinks: value.pageHeaderLinks,
                    component: value.container
                };
            }

            if (key !== 'path' && key !== 'container' && key !== 'title' && key !== 'pageHeaderLinks') {
                let nestedArray = getRoutes(value);
                routeArray = routeArray.concat(nestedArray);
            }
        });

        return routeArray;
    };

    return {
        getRoutes,
    }
};

/**
 * Render a route with potential sub routes
 */
function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={props => <route.component {...props} routes={route.routes}/>}
        />
    );
}

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes({routes}) {
    return (
        <Switch>
            {routes.map((route, i) => {
                return <RouteWithSubRoutes key={route.key} {...route} />;
            })}
            <Redirect to="/admin" />
        </Switch>
    );
}
