import React from "react";
import {camelCase, startCase} from 'lodash';

// //My Account
// import MyAccountContainer from 'modules/Admin/User/MyAccount/Container';
//
// // Customer
// import CustomerContainer from 'modules/Admin/Customer/Container';
// import CustomerAddContainer from "modules/Admin/Customer/Add/Container";
// import CustomerEditContainer from "modules/Admin/Customer/Edit/Container";
// import CustomerUserAddContainer from "modules/Admin/Customer/User/Add/Container";
// import CustomerUserEditContainer from "modules/Admin/Customer/User/Edit/Container";
//
//
// // Subscription
// import SubscriptionContainer from 'modules/Admin/Subscription/Container';
// import SubscriptionAddContainer from "modules/Admin/Subscription/Add/Container";
// import SubscriptionEditContainer from "modules/Admin/Subscription/Edit/Container";
//
//
// // Payment
// import PaymentContainer from 'modules/Admin/Payment/Container';
// import PaymentAddContainer from "modules/Admin/Payment/Add/Container";
// import PaymentEditContainer from "modules/Admin/Payment/Edit/Container";
// import PaymentViewContainer from "modules/Admin/Payment/View/Container";
//
//
// // Refund
// import RefundContainer from 'modules/Admin/Refund/Container';
// import RefundAddContainer from "modules/Admin/Refund/Add/Container";
// import RefundViewContainer from "modules/Admin/Refund/View/Container";

/**
 * This creates complete routing object for a module.
 * Creates Outer container (Customers), add page (Add Customer), edit page (Edit Customer) and etc.
 * This method can be used even if all the sub pages do not exist.
 * If there is no corresponding page found, default Error page will be shown
 *
 * @param routeName - this should be the path after ROUTE_PREFIX. Ex: customer, user/my-account
 * @param folderName - Main Folder name or folder path from Admin/. Case sensitive Ex: Customer, User/MyAccount
 * @param title - Title of the page header of outer Container. Ex: Customers
 * @param subTitle - Suffix of the child page title. Ex: Customer. This will be used as 'Add' + 'Customer, 'Edit' + 'Customer'
 * @param headerIcon - This is used to construct page header link. Ex: icon-users4
 */
export const getRouteForModule = (routeName, folderName, title, subTitle, headerIcon) => {
    let routeModuleObject = getRouteObject(routeName, null, folderName, title);

    routeModuleObject["ADD"] = getRouteObject(routeName, 'add', folderName, title, subTitle, headerIcon);
    routeModuleObject["EDIT"] = getRouteObject(routeName, 'edit', folderName, title, subTitle, headerIcon);
    routeModuleObject["VIEW"] = getRouteObject(routeName, 'view', folderName, title, subTitle, headerIcon);
    routeModuleObject["META"] = getRouteObject(routeName, 'meta', folderName, title, subTitle, headerIcon);
    routeModuleObject["DISPATCH"] = getRouteObject(routeName, 'dispatch', folderName, title, subTitle, headerIcon);

    return routeModuleObject;
};

const ROUTE_PREFIX = '/admin/';

/**
 * This returns a single route object
 * Ex: {
 *             "path": '/admin/customers/add',
 *            "title": 'Add Customer',
 *           "pageHeaderLinks": [{title: 'Customers', url: '/admin/customers', icon: 'icon-users4'}],
 *          "container": CustomerAddContainer,
 *     }
 *
 * @param routeName - this should be the path after ROUTE_PREFIX. Ex: customer, user/my-account
 * @param subPath - if it is not the outer container, then specify the sub path in lowercase. Ex: add
 * @param folderName - Main Folder name or folder path from Admin/. Case sensitive Ex: Customer, User/MyAccount
 * @param title - Title of the page header of outer Container. Ex: Customers
 * @param subTitle - Suffix of the child page title. Ex: Customer. This will be used as 'Add' + 'Customer, 'Edit' + 'Customer'
 * @param headerIcon - This is used to construct page header link. Ex: icon-users4
 */
export const getRouteObject = (routeName, subPath, folderName, title, subTitle, headerIcon) => {
    let routeObject = {};
    const pascalSubPath = subPath ? startCase(camelCase(subPath)).replace(/ /g, '') : '';
    const subPathSuffix = subPath ? '/' + subPath : '';

    routeObject['path'] = ROUTE_PREFIX + (routeName ? routeName : '') + subPathSuffix;
    routeObject['title'] = pascalSubPath ? pascalSubPath + ' ' + subTitle : title;

    const importPath = (folderName ? folderName + '/' : '') + (pascalSubPath ? pascalSubPath + '/' : '') + 'Container';
    routeObject['container'] = React.lazy(() => import('modules/Admin/' + importPath));

    if (headerIcon) {
        routeObject['pageHeaderLinks'] = [{title: title, url: (ROUTE_PREFIX + routeName), icon: headerIcon}];
    }

    return routeObject;
};
